import React from 'react';

function RulesTabContent({ activeTab }) {
  return (
    <div
      className={`tab-pane fade ${activeTab === "rules" ? 'active show' : ''}`}
      id="rules" role="tabpanel" aria-labelledby="rules-tab">
      <div className='col pt-3'>
        <div>
          <h2>Rules/Entry Requirement:</h2>
          <ol className="">
            <li>
              The contest is open to the public.
            </li>
            <li>
              Participants may only join one team.
            </li>
            <li>
              Teams will be required to elect a team lead or team representative who will act as a contact point with the Spirathon organizers.
            </li>
            <li>
              Judges’ decisions are final and are not subject to review.
            </li>
            <li>
              The application idea should be unique, freshly creative and not copied from others.
            </li>
            <li>
              Participants must select dataset from a specific sector of Nepal, identify a prevailing problem around that sector and develop an app as a solution.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default RulesTabContent;
