import Swal from 'sweetalert2'

export const ConfirmDialogueBox = function (
    { title, message, onConfirm, type, confirmButtonText, cancelButtonText, onCancel = () => {}, allowOutsideClick = true },
  ...options
  ) {
  Swal.fire({
    title: title ? title : "Confirm Action",
    text: message ? message : "Are you sure you want to perform this action",
    showCancelButton: options.hasOwnProperty("showCancelButton")
      ? options.showCancelButton
      : true,
    showConfirmButton: options.hasOwnProperty("showConfirmation")
      ? options.showConfirmation
        : true,
    type: type ? type : "info",
    confirmButtonText: confirmButtonText ? confirmButtonText: "Submit",
      cancelButtonText: cancelButtonText ? cancelButtonText : "Cancel",
      allowOutsideClick: allowOutsideClick 
  }).then(result => {
    if (result.value) {
      onConfirm();
      }
      if (result.dismiss) {
          onCancel();
      }
  });
  };