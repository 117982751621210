import React from 'react';
import RegisterForm from './RegisterForm';

function RegisterTabContent({ activeTab }) {
  return (
    <div 
    className={`tab-pane fade ${activeTab === "reg" ? 'active show' : ''}`}  
    id="reg" role="tabpanel" aria-labelledby="reg-tab">
      <RegisterForm />
    </div>
  );
}

export default RegisterTabContent;
