import React,{ Component } from "react";
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom'

import {
    NavLink,
    NavItem
} from 'reactstrap'

 class NavItemLink extends Component {
    render() { 
        let isActive = this.props.pathname === this.props.to
        return (
            <NavItem className={isActive ? 'active' : ''}>
                <NavLink to={this.props.to} className={this.props.classes} aria-current="page" tag={Link}>{this.props.children}</NavLink>
            </NavItem>
        )
    }
}

NavItemLink.propTypes = {
    to: PropTypes.string,
    pathname: PropTypes.string,
    classes: PropTypes.string
};


export default NavItemLink