import {DOMAINS,COUNTRY_CODE } from '../constants/mail.constants'

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
// const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/

export function validateEmail(email) {

    if (!emailRegex.test(email)) {
       return false
    }

    const afterAt = email.split('@')[1]
    // console.log(afterAt)
    const domain = afterAt.split('.')[1];
    const countryCode = afterAt.split('.')[2];
    // console.log(Object.values(DOMAINS), domain)

    if (Object.values(DOMAINS).includes(domain.toUpperCase())) {
        if (
          countryCode &&
          !Object.values(COUNTRY_CODE).includes(countryCode.toUpperCase())
        ) {
          return false
        } 
        return true
      } else {
        return false
      }
  }