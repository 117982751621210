const axios = require("axios")
const { baseUrl } = require("../config/apiUrls")

export const getFromLocalStorage = keyName => {
  const value = localStorage.getItem(keyName)
  return value
}

export const setToLocalstorage = (keyName, valueName) => {
  localStorage.setItem(keyName, valueName)
}

export const setHeaders = () => {
  return {
    'x-access-token': `${getFromLocalStorage('token')}`
  }
}

// const disableForm = () => {
//   const disableDate = new Date('March 14, 2024 12:33:00')
//   const todaysDate = Date.now()
//   if (todaysDate >= disableDate) {
//     return true
//   }
//   return false
// }

export const disableForm = async function(){
  const res = await axios.get(`${baseUrl}checkRegistrationClosed`)
  if (res.data) {
    return res.data.isRegistrationClosed
  } else {
    const disableDate = new Date('March 14, 2024 12:33:00')
      const todaysDate = Date.now()
      if (todaysDate >= disableDate) {
        return true
      }
      return false
  }

}

// module.exports = {
//   getFromLocalStorage,
//   setToLocalstorage,
//   setHeaders,
// }
