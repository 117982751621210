import React, { Component } from 'react';
import Proptypes from 'prop-types'
import { signUp, checkFormDisabled } from '../../store/actions/authActions'
import { NavLink, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getFromLocalStorage } from '../../shared/shared'
import { validateEmail } from '../../helpers/validation';

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            confirmPassword: '',
            phone: '',
            errors: {}
        };
    }

    validateForm = () => {
        const { name, email, password, confirmPassword, phone } = this.state;
        const errors = {};

        if (this.props.formDisabled) {
            console.log(this.props.formDisabled)
            errors.error = "Registration has closed"
            this.setState({ errors });
            return false
        }

        // Email validation

        if (!email) {
            errors.email = 'Please Enter Email'
        }
        else {
            if (!validateEmail(email)) {
                errors.email = 'Please Enter Valid Email'
            }
        }

        const nameRegex = /^[a-zA-Z\s'-]+$/
        if (!name.trim()) {
            errors.name = 'Please Enter Name'
        } else {
            if (!nameRegex.test(name.trim())) {
                errors.name = 'Name can contain alphabets, hyphen or spaces'
            }
        }

        // TODO use new validation regex
        const phoneRegex = /(\+977)?[9][6-9]\d{8}/;
        if (!phoneRegex.test(phone)) {
            if (!phone) {
                errors.phone = 'Please Enter Phone'
            }
            else {
                errors.phone = 'Please Enter Valid Phone';
            }
        }

        // Password validation
        const passwordRegex = /^.*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[\W_]).*$/;
        if (!passwordRegex.test(password)) {
            errors.password = 'Password must be at least 8 characters long and contain at least one uppercase letter, at least one lowercase letter, at least one digit and at least one special character';
        }

        // Password confirmation validation
        if (password !== confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }

        this.setState({ errors });
        return Object.keys(errors).length === 0;
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            let data = { ...this.state, name: this.state.name.trim(), errors: undefined }
            this.props.signup(data, this.props.history);
        }
    };

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    };

    componentDidMount() {
        this.props.checkFormDisabled()
    }


    render() {
        return <Redirect to={'about'} />
        // const { name, email, phone, password, confirmPassword, errors } = this.state;
        // var userId = getFromLocalStorage('userId') || this.props.userId;
        // if (this.props.isLoggedIn) return <Redirect to={`myteam`} />
        // return (
        //     <section className="announcing-wrapper container">
        //         <div className="container-fluid">
        //             <div className="inner-page-block">
        //                 <div className="row justify-content-center">
        //                     <div className="col-md-6">
        //                         <div className="top-part__header">
        //                             <span>SignUp </span>
        //                             <h2>Sign Up</h2>
        //                             <br />
        //                         </div>
        //                     </div>
        //                     <div className="row justify-content-center">
        //                         <div className="col-md-6 mb-3">
        //                             {!(errors.error || this.props.formDisabled) && <div className="">Sign up first to register your team!</div>}
        //                             {(errors.error || this.props.formDisabled) && <div className="alert alert-info mb-2 mt-2 pb-2 pt-2" style={{fontSize: 'larger'}} role="alert"> Registration is now closed. <br/>Thank you for showing your interest in Spirathon 2024!</div>}
        //                             <div className="form-sec form-sec--spirathon2024">
        //                                 <form onSubmit={this.handleSubmit}>
        //                                     <ul>
        //                                         <li>
        //                                             <input
        //                                                 type="text"
        //                                                 className={`${errors.name && 'is-invalid'}`}
        //                                                 id="name"
        //                                                 placeholder="Enter name"
        //                                                 value={name}
        //                                                 onChange={this.handleChange}
        //                                             />
        //                                             {errors.name && <div className="invalid-feedback">{errors.name}</div>}
        //                                         </li>
        //                                         <li>
        //                                             <input
        //                                                 type="email"
        //                                                 className={`${errors.email && 'is-invalid'}`}
        //                                                 id="email"
        //                                                 placeholder="Enter email"
        //                                                 value={email}
        //                                                 onChange={this.handleChange}
        //                                             />
        //                                             {errors.email && <div className="invalid-feedback">{errors.email}</div>}
        //                                         </li>
        //                                         <li>
        //                                             <input
        //                                                 type="text"
        //                                                 className={`${errors.phone ? 'is-invalid' : ''}`}
        //                                                 id="phone"
        //                                                 placeholder="Enter phone"
        //                                                 value={phone}
        //                                                 onChange={this.handleChange}
        //                                                 maxLength={10}
        //                                             />
        //                                             {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
        //                                         </li>
        //                                         <li>
        //                                             <input
        //                                                 type="password"
        //                                                 className={`${errors.password && 'is-invalid'}`}
        //                                                 id="password"
        //                                                 placeholder="Enter password"
        //                                                 value={password}
        //                                                 onChange={this.handleChange}
        //                                             />
        //                                             {errors.password && <div className="invalid-feedback">{errors.password}</div>}
        //                                         </li>
        //                                         <li>
        //                                             <input
        //                                                 type="password"
        //                                                 className={` ${errors.confirmPassword && 'is-invalid'}`}
        //                                                 id="confirmPassword"
        //                                                 placeholder="Confirm password"
        //                                                 value={confirmPassword}
        //                                                 onChange={this.handleChange}
        //                                             />
        //                                             {errors.confirmPassword && <div className="invalid-feedback">{errors.confirmPassword}</div>}
        //                                         </li>
        //                                         {(errors.error || this.props.formDisabled) ?
        //                                             <li>
        //                                                 <button className='common-btn w-100 btn-disabled' disabled={true}>Sign Up</button>
        //                                             </li>
        //                                             :
        //                                             <li>
        //                                                 <button className='common-btn w-100' >Sign Up</button>
        //                                             </li>
        //                                         }

        //                                     </ul>
        //                                 </form>

        //                                 <div className='mt-2 '>
        //                                     Already signed up? &nbsp;
        //                                     <NavLink className="simple-link" to='/signin'>
        //                                         Login here.
        //                                     </NavLink>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </section>
        // )
    }
}

SignUp.propTypes = {
}

const mapStateToProps = state => {
    return {
        email: state.auth.email,
        authError: state.auth.authError,
        isLoggedIn: state.auth.isLoggedIn,
        formDisabled: state.auth.formDisabled,
        userId: state.auth.userId,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signup: (userInfo, history) => dispatch(signUp(userInfo, history)),
        checkFormDisabled: () => dispatch(checkFormDisabled())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp))
