import React, { Component } from 'react'
import { toast } from 'react-toastify';
import axios from "axios";
import { baseUrl } from "../../config/apiUrls";

const primarySources = [{
  field: 'Facebook',
  value: 'facebook',
}, {
  field: 'Youtube',
  value: 'youtube',
}, {
  field: 'Linkedin',
  value: 'linkedin',
}, {
  field: 'Official website',
  value: 'official website',
}, {
  field: 'Friends & Families',
  value: 'friends & families',
},{
  field: 'College/University',
  value: 'College/University',
},
{
  field: 'Other',
  value: 'Other',
}
]

const INITIAL_STATE = {
  name: "",
  email: "",
  phone: "",
  primarySource: "",
  errors: {}
}
class GetInTouch extends Component {

  constructor() {
    super()
    this.state = INITIAL_STATE
  }

  validate() {
    const { primarySource, name, email, phone } = this.state;

    let errors = {}

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      if (!email) {
        errors.email = 'Please Enter Email'
      }
      else {
        errors.email = 'Please Enter Valid Email';
      }
    }

    const phoneRegex = /(\+977)?[9][6-9]\d{8}/;
    if (!phoneRegex.test(phone)) {
      if (!phone) {
        errors.phone = 'Please Enter Phone'
      }
      else {
        errors.phone = 'Please Enter Valid Phone';
      }
    }

    if (!name) {
      errors.name = 'Please Enter Name'
    }

    if (!primarySource) {
      errors.primarySource = 'Choose one'
    }

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.validate()) {
      const { errors, ...data } = this.state
      try {
        let response = await axios.post(`${baseUrl}v1/contactForm`, data)
        if (response.data.success == false) {
          if (response.data.errors)
            this.setErrorState(errors)
        }
        toast.success('Message Submitted')
        this.setState({ ...INITIAL_STATE })
      } catch (error) {
        toast.error('Something went wrong')
      }
    }
  };

  setErrorState = (errors = []) => {
    let err = {}

    if (!Array.isArray(errors)) return

    errors.map(error => {
      return err[error['path']] = error.messge
    })

    this.setState = {
      errors: err
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { errors } = this.state
    return (
      <>
        <section className="announcing-wrapper container mb-5">
          <div className="container-fluid">
            <div className="inner-page-block">
              <div className="contact-us">
                <div className="top-part">
                  <div className="top-part__header">
                    <span>Contact us </span>
                    <h2>Contact us</h2>
                  </div>
                  <br />
                  <br />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-sec ">
                      <form autoComplete="false">
                        <ul>
                          <li>
                            <input className={errors.name ? 'form-control is-invalid was-validated' : 'form-control'} name='name' type="text" placeholder={"Name"} onChange={this.handleChange} value={this.state.name} />
                            {errors.name && <p className='invalid-feedback'>{errors.name}</p>}
                          </li>
                          <li>
                            <input className={errors.email ? 'form-control is-invalid was-validated' : 'form-control'} name='email' type="email" placeholder={"Email"} onChange={this.handleChange} value={this.state.email} />
                            {errors.email && <p className='invalid-feedback'>{errors.email}</p>}

                          </li>
                          <li>
                            <input className={errors.phone ? 'form-control is-invalid was-validated' : 'form-control'} name='phone' type="text" maxLength={10} placeholder={"Phone number"} onChange={this.handleChange} value={this.state.phone} />
                            {errors.phone && <p className='invalid-feedback'>{errors.phone}</p>}

                          </li>
                          <li>
                            <select className={errors.primarySource ? 'form-control is-invalid was-validated' : 'form-control'} name='primarySource' onChange={this.handleChange} value={this.state.primarySource} >
                              <option value="">
                                How did you know about us? Select One
                              </option>
                              {primarySources.map((source, index) =>
                              (<option key={index + '1'} value={source.value}>
                                {source.field}
                              </option>))}
                            </select>
                            {errors.primarySource && <p className='invalid-feedback'>{errors.primarySource}</p>}

                          </li>
                          <li>
                            <input onClick={this.handleSubmit} type="submit" placeholder="" value="submit" />
                          </li>
                        </ul>
                      </form>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="get-in-touch">
                      <div className="get-in-touch__address">
                        <ul>
                          <li>
                            <div className="icon">
                              <span> <img src="assets/images/map.svg" alt="" /></span>
                            </div>
                            <div className="detail">
                              <h4>Location</h4>

                              <p>
                                Spiralogics International <br />
                                4th Floor, Shankhamul Tower, Kathmandu, Nepal.
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <span> <img src="assets/images/phone-alt.svg" alt="" /></span>
                            </div>
                            <div className="detail">
                              <h4>Phone</h4>
                              <p>
                                +977-9767352658
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <span> <img src="assets/images/envelop.svg" alt="" /></span>
                            </div>
                            <div className="detail">
                              <h4>Email</h4>
                              <p>
                                <a href='mailto:spirathon@spiralogics.com'>spirathon@spiralogics.com</a>
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="get-in-touch__social-media">
                        <ul>
                          <li> <a href="https://www.facebook.com/spiralogicsintl" target='_blank' className="facebook" title='Facebook'> <img src="assets/images/facebook.svg" /> </a> </li>
                          <li> <a href="https://www.youtube.com/@lifeatspiralogics"target='_blank'  className="twitter" title='Youtube'> <img src="assets/images/youtube 1.svg" /> </a> </li>
                          <li> <a href="https://www.linkedin.com/company/spiralogics-inc./mycompany" target='_blank' className="linkedin" title='Linkedin'> <img src="assets/images/linkedin.svg" /> </a> </li>
                          <li> <a href="https://www.instagram.com/spiralogicsinternational/" target='_blank' className="instragram" title='Instagram'> <img src="assets/images/instagram.svg" /> </a> </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default GetInTouch
