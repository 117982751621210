import React, { Component } from 'react'
import SignedOutFooter from './SignedOutFooter'
import CountDown from './CountDown'
import SignedInFooter from './SignedInFooter'
import { connect } from 'react-redux'
import Proptypes from 'prop-types'
import { ContactFooter } from './ContactFooter'

class MainFooter extends Component {
  render() {
    //console.og(this.props.isLoggedIn)
    // const link = this.props.email
    const link = this.props.isLoggedIn
      ? <SignedInFooter />
      : <SignedOutFooter />
    return (
      <footer>
        {/* <div className='container'>
          {link}
          <CountDown />
        </div> */}
        <ContactFooter/>
      </footer>
    )
  }
}

const mapStateToProps = state => {
  //console.og(state)
  return {
    email: state.auth.email,
    isLoggedIn: state.auth.isLoggedIn
  }
}

export default connect(mapStateToProps)(MainFooter)
