import axios from "axios";
import React, { Component } from "react";
import { baseUrl } from "../../config/apiUrls";
import { updateRole } from "../../store/actions/authActions";
import { getFromLocalStorage, setHeaders } from "../../shared/shared";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

class SelectWinner extends Component {
    constructor() {
        super()
        this.state = {
            Finalists: [],
            selectedWinnerId: '',
        }
    }

    componentDidMount() {
        try {
            axios
                .get(`${baseUrl}listFinalists?isSelectedValue=true`)
                .then(response => {
                    if (response && response.data) {
                        // set state here
                        this.setState({
                            Finalists: response.data
                        })
                    }
                })
                .catch(err => {
                    //console.log(err)
                })
        } catch (error) {
            console.log(error)
        }
    }

    handleWinnerSelection = (e) => {
        this.setState({ selectedWinnerId: e.target.value })
    }

    selectWinner = async (e) => {
        console.log(this.state.selectedWinnerId)
        try {
            const res = await axios.post(`${baseUrl}updateUserById/${this.state.selectedWinnerId}`, {
                position: '1'
            }, { headers: setHeaders() })
            console.log(res)
            if (res.data.success) {
                toast.success("Winner Selected!")
            } else {
                toast.error("Winner Selection failed!")
            }
        } catch (error) {
            toast.error("Something Went Wrong!")
        }
    }

    render() {
        if (!this.props.isLoggedIn || this.props.role != 'admin') { return <Redirect to='/signin' /> }
        // console.log(this.props)
        let finalists = this.state.Finalists
        return (
            <div className="pt-4 d-flex flex-row justify-content-center" style={{ width: '100%' }}>
                <div className="form-sec" style={{ minWidth: '300px' }}>
                    <div className="section-wrapper">
                        <h3>Winner Selection</h3>
                        <ul>
                            <li>
                                <select onChange={this.handleWinnerSelection}>
                                    <option value={''}>Select a Team</option>
                                    {
                                        finalists.map((finalist, ind) => (
                                            <option key={ind} value={finalist.id}>{finalist.teamName}</option>
                                        ))
                                    }
                                </select>
                            </li>
                        </ul>
                        <div className="mb-2"><button type="button" onClick={this.selectWinner} className="btn common-btn ">Select Member</button></div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.auth.email,
        authError: state.auth.authError,
        isLoggedIn: state.auth.isLoggedIn,
        role: state.auth.role
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateRole: userId => dispatch(updateRole(userId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectWinner)