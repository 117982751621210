import React from 'react';

function TeamTabs({ tabs, activeTab, onTabChange }) {
  return (
    <ul className="nav nav-tabs" role="tablist">
      {tabs.map(tab => (
        <li key={tab.id} className="nav-item" role="presentation">
          <button 
            className={`nav-link  register-tab ${activeTab === tab.id ? 'active' : ''}`} 
            onClick={() => onTabChange(tab.id)} 
            type="button" 
            role="tab" 
            id = {tab.id+"-tab"}
            aria-controls={tab.id} 
            aria-selected={activeTab === tab.id} 
            data-bs-toggle="tab"
          >
            {tab.label}
          </button>
        </li>
      ))}
    </ul>
  );
}

export default TeamTabs;
