import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { teamRegister, teamSaveDraft } from "../../store/actions/userActions";
import UserNavLink from "./UserNavlink";
import { setHeaders } from "../../shared/shared";
import { baseUrl, imageUrl } from "../../config/apiUrls";
import { getFromLocalStorage } from "../../shared/shared";
import { Redirect } from "react-router-dom";
import { Loader } from "../layouts/loader";
import { toast } from "react-toastify";
import { ConfirmDialogueBox } from "../../shared/dialogueBox";
import { checkFormDisabled } from "../../store/actions/authActions";

const SECTORS = [
  { field: 'Agriculture', description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni velit dolorem accusamus molestiae, ad quos eveniet modi aliquid animi quisquam harum fugiat quae illo esse mollitia. Culpa voluptatibus eius neque deleniti, quis tempore veritatis et?" },
  { field: 'Education', description: "Eduction: Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repudiandae consequuntur, temporibus deleniti tenetur debitis vel quod earum totam quam in." },
  { field: 'Environment & Climate Change', description: "Environment & Climate Change: Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repudiandae consequuntur, temporibus deleniti tenetur debitis vel quod earum totam quam in." },
  { field: 'Health', description: "Health: Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repudiandae consequuntur, temporibus deleniti tenetur debitis vel quod earum totam quam in." },
  { field: 'Public Finance', description: "Public Finance: Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repudiandae consequuntur, temporibus deleniti tenetur debitis vel quod earum totam quam in." },
  { field: 'Others', description: "Others" }]
class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamName: "",
      teamLeadEmail: "",
      teamLeadName: "",
      teamLeadInstitution: "",
      teamLeadDesignation: "",
      teamLeadPhone: "",
      teamMembers: [
        {
          name: "",
          email: "",
          phone: "",
          institution: "",
          designation: "",
        },
      ],
      projectTitle: "",
      projectBanner: "",
      bannerFile: "",
      tempBannerImage: "",
      // projectIdea: '',
      projectDescription: "",
      projectTools: [
        {
          toolName: "",
          toolDescription: "",
        },
      ],
      sector: "",
      videoUrl: "",
      showTeam: false,
      isTeamRegistered: false,
    };
  }

  componentDidMount() {
    try {
      let userid;
      if (getFromLocalStorage("userId") || this.props.id) {
        userid = getFromLocalStorage("userId") ? getFromLocalStorage("userId") : this.props.id;
      } else {
        return <Redirect to="/signin" />;
      }

      axios
        .get(`${baseUrl}v1/users/${userid}`, { headers: setHeaders() })
        .then((response) => {
          // console.log(response[`data`])
          const responseData = response[`data`]['data']
          this.changeMystate(responseData);
        })
        .catch((err) => {
          console.log(err)
        });

      this.props.checkFormDisabled(userid)
      this.props.checkRegistrationClosed()
    } catch (err) {
      //console.log(`${err}this`)
      return <Redirect to="/signin" />;
    }
  }

  changeMystate(respo) {
    this.setState({
      teamName: respo.teamName ? respo.teamName : "",
      teamLeadEmail: respo.teamLeadEmail ? respo.teamLeadEmail : "",
      teamLeadName: respo.teamLeadName ? respo.teamLeadName : "",
      teamLeadInstitution: respo.teamLeadInstitution
        ? respo.teamLeadInstitution
        : "",
      teamLeadDesignation: respo.teamLeadDesignation
        ? respo.teamLeadDesignation
        : "",
      teamLeadPhone: respo.teamLeadPhone ? respo.teamLeadPhone : "",
      teamMembers:
        respo.teamMembers.length !== 0
          ? respo.teamMembers
          : [
            {
              name: "",
              email: "",
              phone: "",
              institution: "",
              designation: "",
            },
          ],
      projectTitle: respo.projectTitle ? respo.projectTitle : "",
      projectBanner: respo.projectBanner ? respo.projectBanner : "",
      projectDescription: respo.projectDescription
        ? respo.projectDescription
        : "",
      // projectIdea: respo.projectIdea ? respo.projectIdea : '',
      projectTools:
        respo.projectTools.length !== 0
          ? respo.projectTools
          : [
            {
              toolName: "",
              toolDescription: "",
            },
          ],
      sector: respo.sector ? respo.sector : "",
      videoUrl: respo.videoUrl ? respo.videoUrl : "",
    });
  }

  addTeamMembers(e) {
    this.setState({
      teamMembers: [...this.state.teamMembers, {}],
    });
  }
  addTools(e) {
    this.setState({
      projectTools: [...this.state.projectTools, {}],
    });
  }

  handleRemoveMembers(index) {
    let arr = this.state.teamMembers;
    arr.splice(index, 1);
    this.setState({ teamMembers: arr });
  }

  handleRemoveTools(index) {
    let arr = this.state.projectTools;
    arr.splice(index, 1);
    this.setState({ projectTools: arr });
  }

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    //console.og(this.state)
  };

  //handle changes in dynamic input forms, need to make it dynamic

  handleNameChange(e, index) {
    let teamMembers = this.state.teamMembers
    teamMembers[index].name = e.target.value;
    this.setState({ teamMembers });
  }

  handleEmailChange(e, index) {
    let teamMembers = this.state.teamMembers
    teamMembers[index].email = e.target.value;
    this.setState({ teamMembers });
  }

  handlePhoneChange(e, index) {
    let teamMembers = this.state.teamMembers
    teamMembers[index].phone = e.target.value;
    this.setState({ teamMembers: teamMembers });
  }

  handleDesignationChange(e, index) {
    let teamMembers = this.state.teamMembers
    teamMembers[index].designation = e.target.value;
    this.setState({ teamMembers: teamMembers });
  }

  handleInstitutionChange(e, index) {
    let teamMembers = this.state.teamMembers
    teamMembers[index].institution = e.target.value;
    this.setState({ teamMembers: teamMembers });
  }

  handleToolChange(e, index) {
    let projectTools = this.state.projectTools
    projectTools[index].toolName = e.target.value;
    this.setState({ projectTools: projectTools });
  }

  handleDescriptionChange(e, index) {
    let projectTools = this.state.projectTools
    projectTools[index].toolDescription = e.target.value;
    this.setState({ projectTools: projectTools });
  }

  handleSubmit(e, userId, submitFlag = false) {
    e.preventDefault();
    // call actions on userAction
    const { tempBannerImage, ...restOfState } = this.state
    if (submitFlag) {
      this.props.registerTeam(this.props.id, restOfState);
    } else {
      this.props.teamSaveDraft(this.props.id, restOfState);
    }
  }

  fileChangedHandler = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        tempBannerImage: reader.result,
        bannerFile: file,
      });
    };
    reader.readAsDataURL(file);
    //console.og(event.target.files[0])
    const formData = new FormData();
    formData.append("banner", event.target.files[0]);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "x-access-token": localStorage.getItem("token"),
      },
    };
    try {
      axios
        .post(`${baseUrl}v1/uploadBanner/${this.props.id}`, formData, config)
        .then((response) => {
          //console.og(response)
          if ((response.data.success = true)) {
            //console.log(response[`data`])
            this.callSetSate(response);
            toast.success("Image Upload Successful");
            //console.og(this.state)
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          toast.error("Image Upload Failed");
          //console.log(err)
        });
    } catch (err) {
      //console.log('error fetching users list')
    }

    //files are uploaded to this url -http://localhost:2000/v1/uploadBanner
  };
  callSetSate = (response) => {
    this.setState({
      projectBanner: response[`data`][`filename`],
    });
  };

  changeMyImageState = (respo) => {
    //console.og(respo)
    this.setState({
      projectBanner: respo,
    });
    //console.og(respo)
  };

  getErrorMessage(field) {
    const errors = this.props.team.teamError
    if (!errors) {
      return ''
    }
    // console.log(errors)
    // 400 response for submition done already gives object
    if (Array.isArray(errors)) {
      let fieldError = errors.find(error => error.path == field)
      if (!fieldError) return ''
      else {
        return <div className="alert alert-danger" >{fieldError.msg}</div>
      }
    } return ''
  }

  getSectorDescription(field) {
    let matchSector = SECTORS.find(sector => sector.field == this.state.sector)
    return matchSector.description
  }

  render() {
    const userId = getFromLocalStorage("userId");
    const { isFormDisabled, isRegistered, isSubmitting } = this.props; //set from store
    let isDisabled = isFormDisabled || isRegistered;
    // console.log(isFormDisabled)
    let { tempBannerImage, projectBanner, videoUrl } = this.state;
    let videoPreview = null;
    let imagePreview = null;
    if (projectBanner) {
      // console.log(`${imageUrl}${projectBanner}`)
      imagePreview = <img style={{ objectFit: "contain", maxWidth: '250px' }} src={`${imageUrl}${projectBanner}`} alt="Project Banner" />;
    }
    else if (tempBannerImage) {
      imagePreview = <img style={{ objectFit: 'contain', maxWidth: '250px' }} src={tempBannerImage} />;
    } else {
      imagePreview = <p className="mt-2">Please select an image for preview</p>;
    }
    // if(videoUrl) {
    //   videoPreview =  <iframe style={{ objectFit: 'contain' }} src={videoUrl} ></iframe>
    // }
    if (1) {
      return (
        <>
          {/* <UserNavLink /> */}

          <div>
            <div>
              {
                isDisabled &&
                <div className="row justify-content-center">
                  <div className="col-md-12 mb-2">
                    <div className="alert alert-info mb-2 mt-2 pb-2 pt-2" role="alert">
                      {isRegistered
                        ? <span className="">Form has been disabled. <br /> Reason: Registration Complete</span>
                        : <span className="">Form has been disabled. <br /> Reason: Registration Closed </span>
                      }
                    </div>
                  </div>
                </div>
              }
              <div className="my-5">
                <div className="">
                  {
                    !isDisabled &&
                    <>
                      <span>IMPORTANT! If you save as draft, please ensure that you complete the registration before the deadline by submitting the form.  </span>
                      <span className="d-block">Please fill all the fields in the required (&nbsp;<span className="required-asterik p-0">*</span>&nbsp;) sections before submitting the form.</span>
                    </>

                  }
                  <div className="form-sec form-sec--spirathon2024">
                    <form autoComplete="off">
                      <fieldset disabled={isDisabled}>
                        <div className="upload-photo-section">
                          {/* <img src="/assets/images/jpgs/about/image-01.jpg" alt="sample image" className="iamfixed" /> */}
                          <h2 className="mb-3">
                            Please upload a Cover Photo. Cover photo should contain
                            all the team members.
                          </h2>
                          <div className="upload-container mb-3">
                            <div className="upload">
                              <div className="fileUploadInput">
                                <input
                                  type="file"
                                  id="banner"
                                  placeholder="Team banner"
                                  onChange={this.fileChangedHandler}
                                />
                                <button>+</button>
                              </div>
                            </div>
                            <div className="img-box">{imagePreview}</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="section-wrapper">
                              <h3>Team Name <span className="required-asterik">*</span> </h3>
                              <ul>
                                <li>
                                  <input
                                    id="teamName"
                                    type="text"
                                    placeholder=" Team name"
                                    value={this.state.teamName}
                                    onChange={this.handleChange}
                                    required
                                  />
                                  {this.getErrorMessage("teamName")}
                                </li>
                              </ul>

                              <h3>Team Introductory Video</h3>
                              <ul>
                                <li>
                                  <input
                                    id="videoUrl"
                                    type="url"
                                    placeholder="Team introductory video url"
                                    value={this.state.videoUrl}
                                    onChange={this.handleChange}
                                    required
                                  />
                                  {/* <div className="img-box">{videoPreview}</div> */}
                                  {this.getErrorMessage(`videoUrl`)}
                                </li>
                              </ul>

                              <h3>Team Leader <span className="required-asterik">*</span></h3>
                              <ul>
                                <li>
                                  <input
                                    id="teamLeadName"
                                    type="text"
                                    placeholder="Team leader's name"
                                    value={this.state.teamLeadName}
                                    onChange={this.handleChange}
                                  />
                                  {this.getErrorMessage("teamLeadName")}

                                </li>
                                <li>
                                  <input
                                    id="teamLeadPhone"
                                    type="tel"
                                    maxLength="10"
                                    placeholder=" Team leader's phone number"
                                    value={this.state.teamLeadPhone}
                                    onChange={this.handleChange}
                                  />
                                  {this.getErrorMessage("teamLeadPhone")}

                                </li>
                                <li>
                                  <input
                                    id="teamLeadEmail"
                                    type="email"
                                    placeholder=" Team leader's email address"
                                    value={this.state.teamLeadEmail}
                                    onChange={this.handleChange}
                                    required
                                  />
                                  {this.getErrorMessage("teamLeadEmail")}

                                </li>
                                <li>
                                  <input
                                    id="teamLeadInstitution"
                                    type="text"
                                    placeholder=" Team leader's academic institution"
                                    value={this.state.teamLeadInstitution}
                                    onChange={this.handleChange}
                                    required
                                  />
                                  {this.getErrorMessage("teamLeadInstitution")}
                                </li>
                              </ul>

                              <div className="team-member-wrapper">
                                <h3>Team Members <span className="required-asterik">*</span></h3>
                                {this.state.teamMembers.length > 0
                                  ? this.state.teamMembers.map((member, index) => (
                                    <ul key={index}>
                                      {index > 0 ? <hr /> : null}
                                      <li>
                                        <input
                                          type="text"
                                          placeholder=" Team member's name"
                                          value={member.name}
                                          onChange={(e) => this.handleNameChange(e, index)}
                                          required
                                        />
                                        {this.getErrorMessage(`teamMembers[${index}].name`)}
                                      </li>
                                      <li>
                                        <input
                                          type="text"
                                          placeholder=" Team member's phone"
                                          value={member.phone}
                                          onChange={(e) => this.handlePhoneChange(e, index)}
                                          maxLength={10}
                                        />
                                        {this.getErrorMessage(`teamMembers[${index}].phone`)}

                                      </li>
                                      <li>
                                        <input
                                          type="text"
                                          placeholder=" Team member's email"
                                          value={member.email}
                                          onChange={(e) => this.handleEmailChange(e, index)}
                                        />
                                        {this.getErrorMessage(`teamMembers[${index}].email`)}

                                      </li>
                                      <li>
                                        <input
                                          type="text"
                                          placeholder=" Team member's academic institution"
                                          value={member.institution}
                                          onChange={(e) =>
                                            this.handleInstitutionChange(e, index)
                                          }
                                          required
                                        />
                                        {this.getErrorMessage(`teamMembers[${index}].institution`)}

                                      </li>
                                      <div className="">
                                        {index === 0 ? null : (
                                          <>
                                            <button
                                              type="button"
                                              className="btn common-btn  common-btn--cancel"
                                              onClick={(e) =>
                                                this.handleRemoveMembers(index)
                                              }
                                            >
                                              Delete member
                                            </button>

                                          </>
                                        )}
                                      </div>
                                    </ul>
                                  ))
                                  : "No input field"}
                                <div className="mb-3">
                                  {this.state.teamMembers.length >= 2 ? null : (
                                    <>
                                      <button
                                        type="button"
                                        className="common-btn common-btn--outline"
                                        onClick={(e) => this.addTeamMembers(e)}
                                      >
                                        Add new member
                                      </button>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="section-wrapper">
                              <h3>About Your Project <span className="required-asterik">*</span></h3>

                              <ul>
                                <li>
                                  <select id="sector" name="sector" value={this.state.sector} onChange={this.handleChange}>
                                    <option value={""} placeholde="select sector">Select Sector</option>
                                    {SECTORS.length && SECTORS.map((optionValue, index) => <option key={optionValue.field + index} value={optionValue.field}>{optionValue.field}</option>)}
                                  </select>
                                  {/* {this.state.sector && <div className="form-sec--spirathon2024 mt-2">{ this.getSectorDescription(this.state.sector)} </div>} */}
                                  {this.getErrorMessage(`sector`)}
                                </li>
                              </ul>
                              <ul>
                                <li>
                                  <input
                                    id="projectTitle"
                                    type="text"
                                    placeholder=" Project title"
                                    value={this.state.projectTitle}
                                    onChange={this.handleChange}
                                    required
                                  />
                                  {this.getErrorMessage(`projectTitle`)}

                                </li>
                                <li>
                                  <textarea
                                    id="projectDescription"
                                    rows="10"
                                    value={this.state.projectDescription}
                                    onChange={this.handleChange}
                                    placeholder="Project description  (must include problem statement, proposed solutions, and how your app/ concept will resolve the problem)"
                                    required
                                  />
                                  {this.getErrorMessage(`projectDescription`)}

                                </li>
                              </ul>

                              <div className="team-member-wrapper ">

                                <h3>Project Tools <span className="required-asterik">*</span></h3>
                                {this.state.projectTools.length > 0
                                  ? this.state.projectTools.map((tool, index) => {
                                    return (
                                      <ul key={index}>
                                        {index > 0 ? <hr /> : null}
                                        <li>
                                          <input
                                            value={tool.toolName}
                                            onChange={(e) =>
                                              this.handleToolChange(e, index)
                                            }
                                            type="text"
                                            placeholder="Tool name (For eg: node.js)"
                                            required
                                          />
                                          {this.getErrorMessage(`projectTools[${index}].toolName`)}

                                        </li>
                                        <li>
                                          <textarea
                                            value={tool.toolDescription}
                                            onChange={(e) =>
                                              this.handleDescriptionChange(e, index)
                                            }
                                            name="descriptions"
                                            id=""
                                            rows="5"
                                            placeholder="Why did you choose this tool?"
                                            required
                                          />
                                          {this.getErrorMessage(`projectTools[${index}].toolDescription`)}
                                        </li>
                                        <div className="">
                                          {index === 0 ? null : (
                                            <button
                                              type="button"
                                              className="btn common-btn common-btn--cancel"
                                              onClick={(e) => this.handleRemoveTools(index)}
                                            >
                                              Delete Tool
                                            </button>
                                          )}
                                        </div>
                                      </ul>
                                    );
                                  })
                                  : "No input field"}
                                <div className="mb-2">
                                  <button
                                    type="button"
                                    className="btn common-btn "
                                    onClick={(e) => this.addTools(e)}
                                  >
                                    Add new tool
                                  </button>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div className=" d-flex justify-content-between">
                          <div className="" style={{ textAlign: "right" }}>
                            <button
                              type="button"
                              onClick={(e) => this.handleSubmit(e, userId)}
                              className="btn common-btn common-btn--white"
                              id="save-btn"
                              disabled={isSubmitting}
                            > Save as draft
                            </button>
                            <button
                              type="button"
                              onClick={(e) => ConfirmDialogueBox({ title: "Are you sure you want to submit?", message: "Once submitted you won't be able to edit the form.", onConfirm: () => this.handleSubmit(e, userId, true), type: "info", confirmButtonText: "Submit", cancelButtonText: "Cancel" })}
                              className="btn common-btn"
                              id="save-btn"
                              disabled={isSubmitting ? true : false}
                            >
                              Save and Submit
                            </button>
                            {!!isSubmitting && <span>Sending...</span>}
                          </div>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
              {
                isDisabled &&
                <div className="row justify-content-center">
                  <div className="col-md-12 mb-2">
                    <div className="alert alert-info mb-2 mt-2 pb-2 pt-2" role="alert">
                      {isRegistered
                        ? <span className="">Form has been disabled. <br /> Reason: Registration Complete</span>
                        : <span className="">Form has been disabled. <br />Reason: Registration Has Closed</span>
                      }
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </>
      );
    } else {
      return <Loader></Loader>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    email: state.auth.email,
    isLoggedIn: state.auth.isLoggedIn,
    id: state.auth.userId,
    isRegistered: state.auth.registered,
    team: state.team,
    isFormDisabled: state.auth.formDisabled,
    isSubmitting: state.team.sendingForm
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerTeam: (id, teamInfo) => {
      dispatch(teamRegister(id, teamInfo));
    },
    teamSaveDraft: (id, teamInfo) => {
      dispatch(teamSaveDraft(id, teamInfo))
    },
    checkFormDisabled: (userId) => dispatch(checkFormDisabled(userId)),
    checkRegistrationClosed: () => dispatch(checkFormDisabled())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
