import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { getFromLocalStorage } from '../../shared/shared'
import RegisteredNavLink from './RegisteredNavLink';
import NotRegisteredNavLink from './NotRegisteredNavLink'
class UserNavLink extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    //console.og(getFromLocalStorage('userId'))
  }

  render() {
    const links = this.props.registered ? <NotRegisteredNavLink /> : <NotRegisteredNavLink />
    //console.og(this.props.registered)
    let id = getFromLocalStorage('userId');
    return (
      <div>
        {links}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    registered: state.auth.registered
  }
}

export default connect(mapStateToProps)(UserNavLink)
