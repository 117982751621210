import React, { useEffect, useState } from 'react';
import TeamTabs from './TeamTabs';
import RulesTabContent from './RulesTabContent';
import RegisterTabContent from './RegisterTabContent';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getFromLocalStorage } from '../../shared/shared';

function MyTeam({ isLoggedIn, id }) {
  const [activeTab, setActiveTab] = useState('reg');
  const [redirectToSignIn, setRedirectToSignIn] = useState(false);

  useEffect(() => {
    const userIdFromLocalStorage = getFromLocalStorage("userId");
    if (!userIdFromLocalStorage && !id) {
      setRedirectToSignIn(true)
    }
  })

  if (redirectToSignIn) {
    return <Redirect to="/signin" />
  }

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const tabs = [
    { id: 'rules', label: 'Rules' },
    { id: 'reg', label: 'Register' },
  ];

  return (
    <section className="announcing-wrapper" style={{ minHeight: 'auto', marginTop: '3rem' }}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="top-part">
              <div className="top-part__header">
                <span>My Team</span>
                <h2>My Team</h2>
              </div>
            </div>
          </div>
        </div>
        <TeamTabs tabs={tabs} activeTab={activeTab} onTabChange={handleTabChange} />
        <div className="tab-content">
          <RulesTabContent activeTab={activeTab} />
          <RegisterTabContent activeTab={activeTab} />
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    email: state.auth.email,
    isLoggedIn: state.auth.isLoggedIn,
    id: state.auth.userId,
  };
};

export default connect(mapStateToProps)(MyTeam);
