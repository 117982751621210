import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getFromLocalStorage } from "../../shared/shared";
import { NavItem, Navbar, NavLink, Container, Nav } from "reactstrap";
import NavItemLink from "../layouts/Header/NavItemLink";

class NotRegisteredNavLink extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let id = getFromLocalStorage("userId") || this.props.userId;
    let { location } = this.props
    let pathname = location.pathname
    return (
      <div className="dashnav mb-3">
        <Navbar >
          <Nav>
            <NavItemLink to="/rules" pathname={pathname} >
              Rules
            </NavItemLink>
            <NavItemLink to={`/team/edit/${id}`} pathname={pathname} >
              Register
            </NavItemLink>
          </Nav>
        </Navbar>
      </div >

    );
  }
}

const mapStateToProps = state => {
  return {
    isRegistered: state.auth.registered,
    userId: state.auth.userId
  };
};

export default withRouter(connect(mapStateToProps)(NotRegisteredNavLink));
