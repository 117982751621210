import { TEAM_REGISTER_SUCCESS, TEAM_REGISTER_ERROR, UPDATE_PROPS, TEAM_SAVE_DRAFT_SUCCESS, TEAM_SAVE_DRAFT_ERROR, SENDING_TEAM_REGISTRATIN_FORM } from './actionTypes'
import { baseUrl } from '../../config/apiUrls'
import { setHeaders } from '../../shared/shared'
import axios from 'axios';
import { toast } from 'react-toastify'

export const teamSaveDraft = (id, teamDetails) => {
  return async (dispatch, getState) => {
    try {
      dispatch(sendingRegisterTeamForm())
      // setting this to false saves as draft
      teamDetails.submitFlag = false;
      // const res = await axios.put(`${baseUrl}v1/users/${id}`, teamDetails, { headers: setHeaders() })
      const res = await axios.post(`${baseUrl}v1/registerTeam/${id}`, teamDetails, { headers: setHeaders() })
      if (res.data.success == false) {
        let error = 'Something went wront'
        if (res && res.data && res.data.errors) {
          toast.error(res.data.message || 'Validation Error, Some fields are not valid')
          error = res.data.errors
        } else {
          error = res.data.message || error
          toast.error(error)
        }
        dispatch(saveAsDraftError(error))
      } else {
        toast.success("Saved Successfully!")
        dispatch(saveAsDraftSuccess(res))
      }
    }
    catch (err) {
      let error = err
      if (err.response && err.response.data && err.response.data.errors) {
        toast.error(err.response.data.message || 'Validation Error, Some fields are not valid')
        error = err.response.data.errors
      } else {
        error = err.message
        toast.error(error)
      }
      dispatch(saveAsDraftError(error))
    }
  }
}
export const teamRegister = (id, teamDetails) => {
  //console.og(teamDetails)
  return async (dispatch, getState) => {
    try {
      // setting this to true registers the user
      dispatch(sendingRegisterTeamForm())
      teamDetails.submitFlag = true;
      const res = await axios.post(`${baseUrl}v1/registerTeam/${id}`, teamDetails, { headers: setHeaders() })
        if (res.data.success == false) {
          let error = 'Something went wront'
          if (res && res.data && res.data.errors) {
            toast.error(res.data.message || 'Validation Error, Some fields are not valid')
            error = res.data.errors
          } else {
            error = res.data.message || error
            toast.error(error)
          }
          dispatch(saveAsDraftError(error))
        } else {
        toast.success("Team Registration Successful!")
        dispatch(teamRegisterSuccess(res))
        dispatch(changeRegisterProps())
      }
    }
    catch (err) {
      let error = err
      if (err.response && err.response.data && err.response.data.errors) {
        toast.error(err.response.data.message || 'Validation Error, Some fields are not valid')
        error = err.response.data.errors
      } else {
        error = err.message
        toast.error(error)
      }
      dispatch(teamRegisterFail(error))
    }
  }
}

function sendingRegisterTeamForm() {
  return {
    type: SENDING_TEAM_REGISTRATIN_FORM
  }
}

function changeRegisterProps() {
  return {
    type: UPDATE_PROPS,
  }
}
function teamRegisterSuccess(res) {
  return {
    type: TEAM_REGISTER_SUCCESS,
    res
  }
}

function teamRegisterFail(err) {
  return {
    type: TEAM_REGISTER_ERROR,
    err
  }
}
function saveAsDraftSuccess(res) {
  return {
    type: TEAM_SAVE_DRAFT_SUCCESS,
    res
  }
}

function saveAsDraftError(err) {
  return {
    type: TEAM_SAVE_DRAFT_ERROR,
    err
  }
}