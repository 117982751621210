import React from 'react'

const NotFound = () => {
  return (
    <main>
      <div className="not-found-page">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-6 offset-md-3 text-center">
              <h1 className="display-4">404</h1>
              <p className="lead">Sorry, the page you're looking for could not be found.</p>
              <button className="btn btn-outline-info mb-2" >
                <a href="/" >Go to Homepage</a>
              </button>
              {/* <img src="/assets/images/graphics/registration_nobg-02.png" alt="Error Image" className="error-image img-fluid mb-4" /> */}
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default NotFound
