import React, { Component } from "react";
import axios from "axios";
import TextRender from "../layouts/renderText";
import RenderSquareImage from "../layouts/imageRenderSquare";
const parseString = require("xml2js").parseString;
const { baseUrl } = require('../../config/apiUrls')

class Spirathon extends Component {
  constructor() {
    super();
    this.state = {
      // EventDays: [],
      Mentors: [],
      CoreTeam: [],
      WorkShop: [],
      Volunteers: []
    };
  }
  componentDidMount() {
    // axios.get(`xmls/spirathon17.xml`).then(response => {
    //   //console.og(response)
    //   parseString(response.data, (error, result) => {
    //     if (error) {
    //       //console.og('error')
    //     } else {
    //       //console.og(result['Spirathon']['EventDays'][0]['image'])
    //       //console.og(result['Spirathon']['Volunteers'][0]['image'])
    //       //console.og(result['Spirathon']['WorkShop'][0]['image'])
    //       this.setState({
    //         WorkShop: [...result["Spirathon"]["WorkShop"][0]["image"]]
    //         // EventDays: [...result['Spirathon']['EventDays'][0]['image']],
    //         // Volunteers: [...result['Spirathon']['Volunteers'][0]['image']]
    //       });
    //     }
    //   });
    // });
    // axios.get(`xmls/about.xml`).then(response => {
    //   parseString(response.data, (error, result) => {
    //     if (error) {
    //       //console.log('error')
    //     } else {
    //       //console.og(result)
    //       this.setState({
    //         Mentors: [...result["Spirathon"]["Mentors"][0]["Description"]],
    //         CoreTeam: [...result["Spirathon"]["Core"][0]["Description"]],
    //         Volunteers: [...result["Spirathon"]["Volunteers"][0]["Description"]]
    //       });
    //     }
    //   });
    // });
    console.log(baseUrl)
    try {
      axios
        .get(`${baseUrl}listFinalists?isSelectedValue=true`)
        .then(response => {
          if (response && response.data) {
            // set state here
            this.setState({
              Finalists: response.data
            })
          }
        })
        .catch(err => {
          //console.log(err)
        })
    } catch (err) {
      //console.log('error fetching users list')
    }
  }

  sortAndRenderFinalist = (items) => {
    // sort to display winner in top
    // winner has position = 1 , otherwise position = 0
    items.sort((a, b) => {
      return parseInt(a.position) < parseInt(b.position) || -(parseInt(a.position) > parseInt(b.position))
    })
    return items.map((item, index) => {
      if (parseInt(item.position) == 1) {
        return (<div key={index} className="table-row">
          <p className="w-s blinking-bg">{`0${index + 1}`}</p>
          <p className="w-t blinking-bg">{item.teamName} (Winner)</p>
          <p className="w-p winner-para blinking-bg">
            <img src="assets/images/winner.png" alt="winner-img" width="24px" />
            <span>{item.projectName}</span>
          </p>
        </div>)
      }
      else {
        return (
          <div key={index} className="table-row">
            <p className="w-s">{`0${index + 1}`}</p>
            <p className="w-t">{item.teamName}</p>
            <p className="w-p winner-para"> 
            <img src="assets/images/winner.png" alt="winner-img" width="24px" className="v-none"/>
              <span>{item.projectName}</span></p>
          </div>
        )
      }
    })
  }

  render() {
    console.log(this.state)
    let finalists = this.state.Finalists || []
    return (
      <div>
        <section className="announcing-wrapper mt-5">
          <div className="container-fluid">
            <div className="inner-page-block">
              <div className="row">
                <div className="col-md-12">
                  <div className="top-part">
                    <div className="top-part__header">
                      <span>SPIRATHON 2024</span>
                      <h2>SPIRATHON 2024</h2>
                    </div> {/* section-header */}
                  </div>
                </div> {/* col */}

                <div className="col-md-6 top-part">
                  <p className="top-part__description top-part__description--full-width"> We are glad to be back<br /> with our flagship event ‘SPIRATHON’! </p>
                  <p>
                    The theme for this year's hackathon is <span className="active-color">"Public Data Innovation"</span> encouraging participants to harness the wealth of information available to create solutions that address real-world challenges and elevate society.
                  </p>
                </div>

                <div className="col-md-6 top-part">
                  <p className="top-part__description top-part__description--full-width theme-items mt-md-0 mt-5">Why should you participate? </p>
                  <div className="px-4">
                    <div className="d-flex objectives">
                      <div className="objective-icon">
                        <img src="assets/images/tick.svg" alt="icon" />
                      </div>
                      <p className="p-0">A platform for the participants to showcase their technical skills as well as to brush up on their nontechnical skills such as presentation, teamwork and leadership. </p>
                    </div>
                    <div className="d-flex objectives">
                      <div className="objective-icon">
                        <img src="assets/images/tick.svg" alt="icon" />
                      </div>
                      <p className="p-0">An opportunity for the students to get an internship or a placement offer. </p>
                    </div>
                    <div className="d-flex objectives">
                      <div className="objective-icon">
                        <img src="assets/images/tick.svg" alt="icon" />
                      </div>
                      <p className="p-0">Basically, an exposure of your TALENT! </p>
                    </div>
                    <div className="d-flex objectives">
                      <div className="objective-icon">
                        <img src="assets/images/tick.svg" alt="icon" />
                      </div>
                      <p className="p-0">Chance for you to make a greater impact through your project. </p>
                    </div>
                  </div>
                </div>{/*col*/}


              </div> {/* row */}
            </div>
            <div className="row">
              <div className="col-md-6 mt-5 top-part">
                <p className="top-part__description top-part__description--full-width theme-items">Participating teams must:</p>
                <div className="px-4">
                  <div className="d-flex objectives">
                    <div className="objective-icon">
                      <img src="assets/images/tick.svg" alt="icon" />
                    </div>
                    <p>Select data set from a specific public sector of Nepal. </p>
                  </div>
                  <div className="d-flex objectives">
                    <div className="objective-icon">
                      <img src="assets/images/tick.svg" alt="icon" />
                    </div>
                    <p>Identify a prevailing problem around that sector.</p>
                  </div>
                  <div className="d-flex objectives">
                    <div className="objective-icon">
                      <img src="assets/images/tick.svg" alt="icon" />
                    </div>
                    <p>Explain how they will solve that problem through an app.</p>
                  </div>
                </div>
              </div>{/*col*/}
              <div className="col-md-6  mt-5 top-part">
                <p className="top-part__description top-part__description--full-width theme-items">
                  Key Public Sectors
                </p>
                <div className="px-4">
                  <div className="d-flex objectives">
                    <div className="objective-icon">
                      <img src="assets/images/tick.svg" alt="icon" />
                    </div>
                    <p>Agriculture</p>
                  </div>
                  <div className="d-flex objectives">
                    <div className="objective-icon">
                      <img src="assets/images/tick.svg" alt="icon" />
                    </div>
                    <p>Education</p>
                  </div>
                  <div className="d-flex objectives">
                    <div className="objective-icon">
                      <img src="assets/images/tick.svg" alt="icon" />
                    </div>
                    <p>Environment and climate change</p>
                  </div>
                  <div className="d-flex objectives">
                    <div className="objective-icon">
                      <img src="assets/images/tick.svg" alt="icon" />
                    </div>
                    <p>Health</p>
                  </div>
                  <div className="d-flex objectives">
                    <div className="objective-icon">
                      <img src="assets/images/tick.svg" alt="icon" />
                    </div>
                    <p>Public finance</p>
                  </div>
                </div>
              </div>
              {/*col*/}

            </div>
          </div>
        </section> {/* announcing */}
        <>
          <section className="road-map">
            <div className="container-fluid">
              <div className="mid-part">
                <div className="mid-part__header">
                  <h2>Roadmap</h2>
                  <div className="lines"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 ">
                  <p className="road-map-description">
                    Explore Spirathon's timeline, starting with registration to development, and presentation stages, fostering innovation and collaboration.
                  </p>
                </div>
              </div>
            </div>

            <div className="container-xl">

              <div className="road-map-content">
                <ul>
                  <li>
                    <div className=" content-left text-center">
                      <h4> Registration Open</h4>
                      <p>16th February</p>
                    </div>
                  </li>
                  <li>
                    <div className="content-right text-center">
                      <h4> Pre-Events</h4>
                      <p>25th February</p>

                    </div>
                  </li>
                  <li>
                    <div className="content-left text-center">
                      <h4>Mentor Orientation</h4>
                      <p>5th March</p>

                    </div>
                  </li>
                  <li>
                    <div className="content-right text-center">
                      <h4>Registration Closes</h4>
                      <p>25th March</p>

                    </div>
                  </li>
                  <li>
                    <div className="content-left text-center">
                      <h4>Top 10 Team Selection</h4>
                      <p>5th April</p>

                    </div>
                  </li>
                  <li>
                    <div className="content-right text-center">
                      <h4>Top 10 Team Presentation</h4>
                      <p>22nd April</p>

                    </div>
                  </li>
                  <li>
                    <div className="content-left text-center">
                      <h4>Top 5 Announcement</h4>
                      <p>25th April</p>

                    </div>
                  </li>
                  <li>
                    <div className="content-right text-center">
                      <h4>Spirathon Finale</h4>
                      <p>27th April</p>

                    </div>
                  </li>
                </ul>
              </div>

            </div>
          </section>
          {/* roadmap */}
        </>
        <section className="selection-process">
          <div className="container-fluid">
            <div className="mid-part">
              <div className="mid-part__header">
                <h2>Selection Process</h2>
                <div className="lines" />
              </div>
            </div>
          </div>
          <div className="container-xl">
            <div className="row">
              <div className="col-md-6">
                <p>
                  As this is an open participation, among the registering teams, 10 teams will be selected based on their raw ideas.  For the event, the members from the selected top 10 teams will need to turn their idea based on the event’s theme into a real-life computer application. The team can build their application in any computer language and platform. There are no set barriers to product development. Each team will be assigned a mentor 2 weeks prior to the main event. The top 5 teams will then be selected and will get to present their application and give a demo in the finale.
                </p>
              </div>
              <div className="col-md-6">
                <p>
                  We will have various pre-events leading up to the main event. Besides the appathon, we will also be inviting keynote speakers and conducting workshops during pre-events. Each team will be scored on different criteria by the judges. Judgement about the products created by the teams in the event will be judged by the professionals invited from top IT companies and non-government organizations.
                </p>
              </div>
            </div>
          </div>
        </section>{/*section-process*/}
        <section className="prize">
          <div className="container-fluid">
            <div className="mid-part">
              <div className="mid-part__header">
                <h2>Prize</h2>
                <div className="lines" />
              </div>
            </div>
          </div>
          <div className="container prize-content">
            <div className="row">
              <div className="col-md-12">
                <span className="cash-prize">Rs. 75,000 Cash Prize! </span>
                <h2 className="announce-soon">WINNER TAKES IT ALL!</h2>
                <p>Only one winning team will take home the total prize money.</p>
              </div>
              <div className="col-md-12 prize-img">
                <img src="assets/images/Prize.svg" alt="prize" />
              </div>
            </div>
          </div>
        </section>
        {/*prize*/}
        <section className="judges">
          <div className="container-fluid" style={{ backgroundColor: '' }}>
            <div className="row">
              {/* Collaborators */}
              <div className="col-md-12 my-4">
                <div className="mid-part">
                  <div className="mid-part__header">
                    <h2>Collaborators</h2>
                    <div className="lines" />
                  </div>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-3 col-sm-6 d-flex align-items-center justify-content-center">
                        <div>
                          <img
                            src="assets/images/024_collaborators/csit.png"
                            alt="collaborators"
                            width="100px"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 d-flex align-items-center justify-content-center">
                        <div className="">
                          <img
                            src="assets/images/024_collaborators/nepalindata.png"
                            alt="collaborators"
                            width="100px"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 d-flex align-items-center justify-content-center">
                        <div className="">
                          <img
                            src="assets/images/024_collaborators/Skill Spark white.png"
                            alt="collaborators"
                            width="120px"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 d-flex align-items-center justify-content-center">
                        <div className="">
                          <img
                            src="assets/images/024_collaborators/sharing_opportunities.png"
                            alt="collaborators"
                            width="100px"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Mentors */}
              <div className="col-md-12">
                <div className="mid-part">
                  <div className="mid-part__header">
                    <h2>Mentors</h2>
                    <div className="lines" />
                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-sm-6 col-md-2">
                      <div className="judges-part">
                        <div className="judges-part__img">
                          <img src="/assets/images/024_mentors/Aabash Shakya.png" alt="profile" />
                        </div>
                        <div className="judges-part__title">
                          <h3>Aabhash Shakya</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <div className="judges-part">
                        <div className="judges-part__img">
                          <img src="/assets/images/024_mentors/Arun Timilsina.png" alt="profile" />
                        </div>
                        <div className="judges-part__title">
                          <h3>Arun Timilsina</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <div className="judges-part">
                        <div className="judges-part__img">
                          <img src="/assets/images/024_mentors/Prati Shrestha.png" alt="profile" />
                        </div>
                        <div className="judges-part__title">
                          <h3>Prati Shrestha</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <div className="judges-part">
                        <div className="judges-part__img">
                          <img src="/assets/images/024_mentors/Sanish Maharjan.png" alt="profile" />
                        </div>
                        <div className="judges-part__title">
                          <h3>Sanish Maharjan</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <div className="judges-part">
                        <div className="judges-part__img">
                          <img src="/assets/images/024_mentors/Sujan Prajapati.png" alt="profile" />
                        </div>
                        <div className="judges-part__title">
                          <h3>Sujan Prajapati</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Guest Mentors */}
              <div className="col-md-12">
                <div className="mid-part">
                  <div className="mid-part__header">
                    <h2>Guest Mentors</h2>
                    <div className="lines" />
                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-sm-6 col-md-2">
                      <div className="judges-part">
                        <div className="judges-part__img">
                          <img src="/assets/images/024_guest_mentors/Mohd_Ayub.png" alt="profile" />
                        </div>
                        <div className="judges-part__title">
                          <h3>Mohd Ayub</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <div className="judges-part">
                        <div className="judges-part__img">
                          <img src="/assets/images/024_guest_mentors/Mahesh_Ghimire.png" alt="profile" />
                        </div>
                        <div className="judges-part__title">
                          <h3>Mahesh Ghimire</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Judges */}
              <div className="col-md-12">
                <div className="mid-part">
                  <div className="mid-part__header">
                    <h2>Judges </h2>
                    <div className="lines" />
                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-sm-6 col-md-2">
                      <div className="judges-part">
                        <div className="judges-part__img">
                          <img src="/assets/images/024_judges/Ayush Subedi.png" alt="profile" />
                        </div>
                        <div className="judges-part__title">
                          <h3>Ayush Subedi</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <div className="judges-part">
                        <div className="judges-part__img">
                          <img src="/assets/images/024_judges/Mona Nyachhyon.png" alt="profile" />
                        </div>
                        <div className="judges-part__title">
                          <h3>Mona Nyachhyon</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <div className="judges-part">
                        <div className="judges-part__img">
                          <img src="/assets/images/024_judges/Surendra Shrestha.png" alt="profile" />
                        </div>
                        <div className="judges-part__title">
                          <h3>Surendra Shrestha</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <div className="judges-part">
                        <div className="judges-part__img">
                          <img src="/assets/images/024_judges/Anjesh Tuladhar.png" alt="profile" />
                        </div>
                        <div className="judges-part__title">
                          <h3>Anjesh Tuladhar</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Panelist */}
              <div className="col-md-12">
                <div className="mid-part">
                  <div className="mid-part__header">
                    <h2>Panelists </h2>
                    <div className="lines" />
                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-sm-6 col-md-2">
                      <div className="judges-part">
                        <div className="judges-part__img">
                          <img src="/assets/images/024_panelist/Saunak Ranjitkar.png" alt="profile" />
                        </div>
                        <div className="judges-part__title">
                          <h3>Saunak Ranjitkar</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <div className="judges-part">
                        <div className="judges-part__img">
                          <img src="/assets/images/024_panelist/Santosh Gartaula.png" alt="profile" />
                        </div>
                        <div className="judges-part__title">
                          <h3>Santosh Gartaula</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <div className="judges-part">
                        <div className="judges-part__img">
                          <img src="/assets/images/024_panelist/Sagun Khanal.png" alt="profile" />
                        </div>
                        <div className="judges-part__title">
                          <h3>Sagun Khanal</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <div className="judges-part">
                        <div className="judges-part__img">
                          <img src="/assets/images/024_panelist/Akash Adhikari.png" alt="profile" />
                        </div>
                        <div className="judges-part__title">
                          <h3>Akash Adhikari</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* finalist */}
              <div className="col-md-12">
                <div className="mid-part">
                  <div className="mid-part__header">
                    <h2>Finalists </h2>
                    <div className="lines" />
                  </div>
                  {
                    finalists.length &&
                    (
                      <div className="finalist-table">
                        <div className="table-row">
                          <p className="table-head w-s">S.N</p>
                          <p className="table-head w-t">Team Name</p>
                          <p className="table-head w-p">Project Title</p>
                        </div>
                        {
                          this.sortAndRenderFinalist(finalists)
                        }
                      </div>
                    )
                  }
                </div>
              </div>
              {/* Top 10 teams */}
              <div className="col-md-12">
                <div className="mid-part">
                  <div className="mid-part__header">
                    <h2>Top 10 Teams </h2>
                    <div className="lines"></div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 team-wrapper">
                      <div className="mb-4 team-img">
                        <img src="assets/images/024_teams/21 Din ma Paisa Double.png" alt="team-img" />
                      </div>
                      <div className="px-2 team-content-section">
                        <div className="d-flex">
                          <div className="team-title">
                            <p>Team Members:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Rajendra Acharya (Lead),
                              <br />Benup Ghimire
                            </p>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="team-title">
                            <p>Team Name:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              21 Dinma Paisa Double
                            </p>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="team-title">
                            <p>Project Title:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Waste Watch Nepal
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>{/*col*/}
                    <div className="col-lg-6 team-wrapper">
                      <div className="mb-4 team-img custom-team-img">
                        <img src="assets/images/024_teams/Deep Learners.jpg" alt="team-image" />
                      </div>
                      <div className="px-2 team-content-section">
                        <div className="d-flex">
                          <div className="team-title">
                            <p>Team Members:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Sumit Yadav (Lead),
                              <br />Raju Kumar Yadav,<br />Prashant Bhandari
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="team-title">
                            <p>Team Name:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Deep Learners
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="team-title">
                            <p>Project Title:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Maithili NGen NewsPortal
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>{/*col*/}
                    <div className="col-lg-6 team-wrapper">
                      <div className="mb-4 team-img">
                        <img src="assets/images/024_teams/CloseAI_group_photo.jpeg" alt="team-image" style={{ objectPosition: 'top' }} />
                      </div>
                      <div className="px-2 team-content-section">
                        <div className="d-flex">
                          <div className="team-title">
                            <p>Team Members:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Ashok Neupane (Lead),
                              <br />Sadhana Panthi
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="team-title">
                            <p>Team Name:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              closeAI
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="team-title">
                            <p>Project Title:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              KhetiApp
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>{/*col*/}
                    <div className="col-lg-6 team-wrapper">
                      <div className="mb-4 team-img">
                        <img src="assets/images/024_teams/MedSumm photo.png" alt="team-image" />
                      </div>
                      <div className="px-2 team-content-section">
                        <div className="d-flex">
                          <div className="team-title">
                            <p>Team Members:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Nijiya Maharjan (Lead),
                              <br />Rubika Bashyal,<br />Oshika Neupane
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="team-title">
                            <p>Team Name:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              MedSumm
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="team-title">
                            <p>Project Title:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              MedSumm
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>{/*col*/}
                    <div className="col-lg-6 team-wrapper">
                      <div className="mb-4 team-img">
                        <img src="assets/images/024_teams/Team X.jpg" alt="team-image" />
                      </div>
                      <div className="px-2 team-content-section">
                        <div className="d-flex">
                          <div className="team-title">
                            <p>Team Members:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Kriti Adhikari (Lead),
                              <br />Keshab Aryal,<br />Hira Lal Shrestha
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="team-title">
                            <p>Team Name:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Team X
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="team-title">
                            <p>Project Title:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Suddhikaran
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>{/*col*/}
                    <div className="col-lg-6 team-wrapper">
                      <div className="mb-4 team-img">
                        <img src="assets/images/024_teams/SaSi.jpg" alt="team-image" />
                      </div>
                      <div className="px-2 team-content-section">
                        <div className="d-flex">
                          <div className="team-title">
                            <p>Team Members:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Sandip Katel (Lead),
                              <br />Sijan Joshi
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="team-title">
                            <p>Team Name:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              SaSi
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="team-title">
                            <p>Project Title:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Nirogya
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>{/*col*/}
                    <div className="col-lg-6 team-wrapper">
                      <div className="mb-4 team-img">
                        <img src="assets/images/024_teams/Relyte 11_group_photo.png" alt="team-image" style={{ objectPosition: 'center center' }} />
                      </div>
                      <div className="px-2 team-content-section">
                        <div className="d-flex">
                          <div className="team-title">
                            <p>Team Members:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Aabhash Aryal (Lead),
                              <br />Utkrista Ghimire,<br />Saurav Raj Subba
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="team-title">
                            <p>Team Name:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Relyte
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="team-title">
                            <p>Project Title:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Vehicle Detection System
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>{/*col*/}
                    <div className="col-lg-6 team-wrapper">
                      <div className="mb-4 team-img">
                        <img src="assets/images/024_teams/negativecreep.png" alt="team-image" style={{ objectPosition: 'top' }} />
                      </div>
                      <div className="px-2 team-content-section">
                        <div className="d-flex">
                          <div className="team-title">
                            <p>Team Members:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Sharad Sharma (Lead),
                              <br />Kritartha Sapkota,<br />Nishant Khadka
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="team-title">
                            <p>Team Name:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Negative Creep
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="team-title">
                            <p>Project Title:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              AgriMarket Nepal
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>{/*col*/}
                    <div className="col-lg-6 team-wrapper">
                      <div className="mb-4 team-img">
                        <img src="assets/images/024_teams/Opinix_group_photo.png" alt="team-image" />
                      </div>
                      <div className="px-2 team-content-section">
                        <div className="d-flex">
                          <div className="team-title">
                            <p>Team Members:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Rajendra Khanal (Lead),
                              <br />Parikshit Adhikari,<br />Prayag Raj Acharya
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="team-title">
                            <p>Team Name:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Opinix
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="team-title">
                            <p>Project Title:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Opinix
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>{/*col*/}
                    <div className="col-lg-6 team-wrapper">
                      <div className="mb-4 team-img">
                        <img src="assets/images/024_teams/Tech Coders.jpg" alt="team-image" />
                      </div>
                      <div className="px-2 team-content-section">
                        <div className="d-flex">
                          <div className="team-title">
                            <p>Team Members:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Shirish Bashyal (Lead),
                              <br />Chetana Chaudhary,<br />Rabina Sedhai
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="team-title">
                            <p>Team Name:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              Tech Coders
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="team-title">
                            <p>Project Title:</p>
                          </div>
                          <div className="team-description">
                            <p>
                              E-Insurance
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>{/*col*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>{/*announce-soon*/}
        <section className="organisers judges mb-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="mid-part">
                  <div className="mid-part__header">
                    <h2>Organizers</h2>
                    <div className="lines" />
                  </div>
                </div>
              </div>
              <div className="col-md-12 organisers-team">
                <div className="top-part">
                  <div className="top-part__header">
                    <h2>Organizing Committee</h2>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/organizing/Nisha Paudel.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Nisha Paudel</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/organizing/RAsu.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Rasu Shrestha</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/organizing/Arun Thapa.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3> Arun Thapa</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/organizing/Mehendi Nakarami.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Mehendi Nakarmi</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/organizing/Sanjok Gyawali.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Sanjok Gyawali</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/organizing/Alika.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3> Alika Maharjan</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/organizing/Prati Shrestha.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Prati Shrestha</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/organizing/Suraksha Khanal.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Surksha Khanal</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-12 organisers-team mt-4">
                <div className="top-part">
                  <div className="top-part__header">
                    <h2>Website &amp; Registration&nbsp;</h2>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Website & Registration/Kirtee Maharjan.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Kirtee Maharjan (Lead)</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Website & Registration/Sanjaya Paudel.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Sanjaya Paudel</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Website & Registration/Sagar Niraula.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Sagar Niraula</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Website & Registration/Milan Dhakal.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3> Milan Dhakal</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Website & Registration/Lumanti.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3> Lumanti Tamrakar</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Website & Registration/Sujeen.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Sujeen Rajak</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Website & Registration/Bibhav Lamichhane.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Bibhav Lamichhane</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Website & Registration/Saphal.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Saphal Thapa</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Website & Registration/Ashutosh.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Aashutosh Dhungana</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Website & Registration/Shrijana kunwar 1.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Shrijana Kunwar</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Website & Registration/Prashamsha Maharjan.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Prashamsha Maharjan</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Website & Registration/Roshan Shah.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Roshan Shah</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Website & Registration/eliza.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Eliza Shrestha</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-12 organisers-team mt-4">
                <div className="top-part">
                  <div className="top-part__header">
                    <h2>Sponsors and Collaborators &nbsp;</h2>
                  </div>
                </div>
              </div>
              <div className="col-sm-2 ">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Sponsors and Collaborators/Kritika Dahal.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Kritika Dahal (Lead)</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2 ">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Sponsors and Collaborators/Sanyam Pradhan.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Sanyam Pradhan</h3>
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-2 ">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Sponsors and Collaborators/Himansu.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Himanshu Jung Bista</h3>
                  </div>
                </div>
              </div> */}
              <div className="col-sm-2 ">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Sponsors and Collaborators/Sujan Prajapati.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Sujan Prajapati</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2 ">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Website & Registration/Prashamsha Maharjan.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3> Prashamsha Maharjan</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2 ">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Sponsors and Collaborators/Rojina Prajapati.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Rojina Prajapati</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-12 organisers-team mt-4">
                <div className="top-part">
                  <div className="top-part__header">
                    <h2>Mentor and Judge  &nbsp;</h2>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Website & Registration/Sagar Niraula.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Sagar Niraula (Lead)</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Mentor and Judge/Ramesh.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Ramesh Hyaunmikha</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Mentor and Judge/Abish Dangol.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Abish Dangol</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Mentor and Judge/Anjani Adhikari.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Anjani Adhikari</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Mentor and Judge/Aabash Shakya.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Aabhash Shakya</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Mentor and Judge/Govinda Wagle.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Govinda Wagle</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Mentor and Judge/Shishir Shrestha.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Shishir Shrestha</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Mentor and Judge/Rajiv Mahat.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Rajiv Mahat</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-12 organisers-team mt-4">
                <div className="top-part">
                  <div className="top-part__header">
                    <h2>Venue and Finance </h2>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Venue and Finance/Anju Chapagain.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Anju Chapagain (Lead)</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Venue and Finance/Suraksha Khanal.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Surksha Khanal</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Venue and Finance/Trishna.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Trishna Pokhrel</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Venue and Finance/Pragya Khatiwada.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Pragya Khatiwada</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Venue and Finance/Pranay Shakya.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Pranay Shakya</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-12 organisers-team mt-4">
                <div className="top-part">
                  <div className="top-part__header">
                    <h2>Participation Management </h2>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Participation Management/Ashutosh.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Aashutosh Dhungana (Lead)</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Participation Management/Sujeen.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Sujeen Rajak</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Participation Management/Avipriya.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Avipriya Shah</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Participation Management/Saugat Basnet.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Saugat Basnet</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Participation Management/Muskan Pandey .png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Muskan Pandey</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-12 organisers-team mt-4">
                <div className="top-part">
                  <div className="top-part__header">
                    <h2>Outreach and Pre-event &nbsp;</h2>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Outreach and Pre-event/Sujan Paudel.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Sujan Poudel (Lead)</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Outreach and Pre-event/Milan Shrestha.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Milan Shrestha</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Outreach and Pre-event/Milan Dhakal.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Milan Dhakal</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Outreach and Pre-event/Bishal Humagain.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Bishal Humagain</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Outreach and Pre-event/Sanish Maharjan.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Sanish Maharjan</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Outreach and Pre-event/Saugat Basnet.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Saugat Basnet</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Outreach and Pre-event/Arun Timilsina.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Arun Timalsina</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Outreach and Pre-event/Ramesh.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Ramesh Hyaunmikha</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-12 organisers-team mt-4">
                <div className="top-part">
                  <div className="top-part__header">
                    <h2>Attendee Management &nbsp;</h2>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Attendee Management/Jenisha Maharjan.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Jenisha Maharjan (Lead)</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Attendee Management/Pragya Khatiwada.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Pragya Khatiwada</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Attendee Management/Muskan Pandey .png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Muskan Pandey</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Attendee Management/Lumanti.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Lumanti Tamrakar</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Attendee Management/Rojina Prajapati.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Rojina Prajapati</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Attendee Management/Rajiv Mahat.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Rajiv Mahat</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Attendee Management/Avipriya.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Avipriya Shah</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-12 organisers-team mt-4">
                <div className="top-part">
                  <div className="top-part__header">
                    <h2>Non-Tech Logistic</h2>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Non-Tech Logistic/Niket.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Niket Dhakal (Lead)</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Venue and Finance/Pranay Shakya.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Pranay Shakya</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Non-Tech Logistic/Sudip Maharajan.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Sudip Maharjan</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Non-Tech Logistic/Priska Shrestha.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Priska Shrestha</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Non-Tech Logistic/Ayush Khatri.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Ayush Khatri</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Non-Tech Logistic/sujal.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Sujal Tandukar</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Non-Tech Logistic/Trishna.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Trishna Pokhrel</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Non-Tech Logistic/Roshan Shah.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Roshan Shah</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Non-Tech Logistic/Jenisha Maharjan.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Jenisha Maharjan</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-12 organisers-team mt-4">
                <div className="top-part">
                  <div className="top-part__header">
                    <h2>Tech Logistic</h2>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Tech Logistic/Milan Shrestha.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Milan Shrestha (Lead)</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Tech Logistic/Kirtee Maharjan.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Kirtee Maharjan</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Tech Logistic/sweekar.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Sweekar Dahal</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Tech Logistic/Rakesh Lakhe.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Rakesh Lakhe</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Tech Logistic/Bibhav Lamichhane.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Bibhav Lamichhane</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Tech Logistic/Rajina Pradhan.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Rajina Pradhan</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-12 organisers-team mt-4">
                <div className="top-part">
                  <div className="top-part__header">
                    <h2>Marketing (&amp; Media)</h2>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Marketing (& Media)/Bishal Humagain.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Bishal Humagain (Lead)</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Marketing (& Media)/Sanyam Pradhan.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Sanyam Pradhan</h3>
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Marketing (& Media)/Himansu.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Himanshu Jung Bista</h3>
                  </div>
                </div>
              </div> */}
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Marketing (& Media)/Kritika Dahal.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Kritika Dahal</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Marketing (& Media)/Sujan Prajapati.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Sujan Prajapati</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Marketing (& Media)/Rajina Pradhan.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Rajina Pradhan</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/Marketing (& Media)/Sudip Maharajan.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Sudip Maharjan</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-12 organisers-team my-4">
                <div className="top-part">
                  <div className="top-part__header">
                    <h2>R &amp; D  &nbsp;</h2>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/R & D/Sanjaya Paudel.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Sanjaya Paudel (Lead)</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/R & D/Sagar Niraula.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Sagar Niraula</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/R & D/Anusha Shrestha.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Anusha Shrestha</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/R & D/Parbaty Prajapati.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Parabaty Prajapati</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/R & D/Rakesh Lakhe.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Rakesh Lakhe</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/R & D/Arun Timilsina.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Arun Timalsina</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/R & D/Abhishek Shrestha.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Abhishek Shrestha</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/R & D/Dipendra Budhathoki.jpg" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Dipendra Budhathoki</h3>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="judges-part">
                  <div className="judges-part__img">
                    <img src="assets/images/R & D/Sujan Paudel.png" alt="profile" />
                  </div>
                  <div className="judges-part__title">
                    <h3>Sujan Poudel</h3>
                  </div>
                </div>
              </div>
            </div>{/*row*/}
          </div>
        </section>{/*organisers*/}
      </div>
    );
  }
}

export default Spirathon;
