import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { checkFormDisabled, signOut } from "../../store/actions/authActions";
import { connect } from "react-redux";


class HomePage extends Component {

  componentDidMount() {
    // Parse the query parameters from the URL
    const searchParams = new URLSearchParams(this.props.location.search);
    const resetSuccess = searchParams.get('reset_success');

    console.log('Reset success:', resetSuccess, searchParams);
    console.log('Reset_success')

    if (resetSuccess === 'true') {
      this.props.signOut(this.props.history)
    }

    this.props.checkRegistrationClosed()
  }


  render() {
    console.log( this.props.isRegistrationClosed)
    return (
      <main className="home">
        <section className="announcing-wrapper">
          <div className="container-fluid">
            <div className="announcing">
              <div className="announcing__heading">
                <div className="announcing__top">
                  <div className="title">Announcing the event launch of <span>2024</span></div>
                  <h1>SPIRATHO<span>N</span></h1>
                  <div className="title">Public Data<span className="title-light">Innovation</span></div>
                </div>
              </div>
              <div className="announcing__comming-soon">
                <h4>April 2024</h4>
              </div>
              <div className="announcing__content">
                <p> New year, new theme, <br />
                  and new format!
                </p>
              </div>
              <div className="button-wrap">
                {
                  this.props.isRegistrationClosed ?
                    <button to="" className="common-btn btn-disabled" disabled>REGISTRATION CLOSED</button>
                    :
                    <NavLink to="/signup" className="common-btn">REGISTER YOUR TEAM</NavLink>
                }
                <NavLink to="/contact-us" className="common-btn contact-btn">CONTACT US</NavLink>
                {/* <a href="" className="common-btn"> Register YOUR TEAM</a>
              <a href="" className="common-btn contact-btn"> CONTACT US</a> */}
              </div>
            </div>
          </div>
        </section>
      </main>
    )
  }
};

const mapStateToProps = state => {
  return {
    isRegistered: state.auth.registered,
    isRegistrationClosed: state.auth.formDisabled,
  }
}


const mapDispatchToProps = dispatch => {
  return {
    signOut: (history) => dispatch(signOut(history)),
    checkRegistrationClosed: () => dispatch(checkFormDisabled())
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage));
